/* eslint-disable jsx-a11y/label-has-associated-control */
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import toast from 'react-hot-toast';
import { AiFillCaretDown } from 'react-icons/ai';
import InputMask from 'react-input-mask';
import MultipleSelect, { components } from 'react-select';
import * as Yup from 'yup';
import ConsentArea from '../../components/ConsentArea';
import FieldError from '../../components/FieldError';
import SubmitButton from '../../components/SubmitButton/SubmitButton';
import Form, { Error, FormGroup, Input, Select, Textarea } from '../../components/ui/form';
import Label from '../../components/ui/form/label/label';
import SectionTitle from '../../components/ui/section-title';
import { Col, Row } from '../../components/ui/Wrapper';
import useFeedbackModal from '../../hooks/useFeedbackModal';
import useFormData from '../../hooks/useFormData';
import contactService from '../../services/contactService';
import consentTypes from '../../utils/constants/consentTypes';
// import customerTypes from '../../utils/constants/customerTypes';

const schemaValues = {
  name: Yup.string().required('Campo obrigatório'),
  email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
  phone: Yup.string().required('Campo obrigatório'),
  zip_code: Yup.string().required('Campo obrigatório'),
  profession: Yup.string().required('Campo obrigatório'),
  job_rule: Yup.array().min(1, 'Campo obrigatório').of(Yup.string()).required('Campo obrigatório'),
  knowledge_source_id: Yup.number().required('Campo obrigatório'),
  presentation: Yup.string()
    .test('len', 'A mensagem deve conter no máximo 1028 caracteres', (val = '') => val.length <= 1028)
    .required('Campo obrigatório'),
  curriculum: Yup.mixed()
    .required('Campo obrigatório')
    .test('fileSize', 'Arquivo deve ter no máximo 2MB', value => {
      const curriculum = document.getElementById('curriculum');
      if (curriculum.value) {
        const { size } = curriculum.files.item(0);
        return size / 1024 / 1024 < 1;
      }
      return true;
    })
    .test('fileType', 'Arquivo deve ser do tipo PDF', value => {
      const curriculum = document.getElementById('curriculum');
      if (curriculum.value) {
        const { type } = curriculum.files.item(0);
        return type === 'application/pdf';
      }
      return true;
    }),
};

const schema = Yup.object().shape(schemaValues);

const consentValidationMessage = 'Você deve consentir com este item para continuar';

const initialValues = {
  name: '',
  email: '',
  phone: '',
  zip_code: '',
  profession: '',
  job_rule: [],
  presentation: '',
  curriculum: '',
  knowledge_source_id: undefined,
};

const handleCloseFeedback = () => {
  window.location.reload();
};

const WorkWithUsForm = () => {
  const { knowledgeSources, isLoading, showLoading, hideLoading } = useFormData({ skipSegments: true });
  const [jobRules, setJobRules] = useState([]);
  // const [unitInfo, setUnitInfo] = useState(null);
  const [validationSchema, setValidationSchema] = useState(schema);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const jobRuleRef = useRef();
  const { FeedbackModal, openModal: openFeedbackModal } = useFeedbackModal({ onClose: handleCloseFeedback });

  const { handleSubmit, setFieldValue, getFieldProps, values, errors, touched, submitCount, resetForm } = useFormik({
    initialValues,
    validationSchema: useCallback(() => validationSchema, [validationSchema]),
    enableReinitialize: true,
    onSubmit: async data => {
      showLoading();
      try {
        const reCaptchaToken = await executeRecaptcha('work_whit_us_form');

        const curriculum = document.getElementById('curriculum');
        const formdata = new FormData();
        formdata.append('name', data.name);
        formdata.append('email', data.email);
        formdata.append('phone', data.phone);
        formdata.append('zip_code', data.zip_code);
        formdata.append('profession', data.profession);
        formdata.append('job_rule[]', data.job_rule);
        formdata.append('presentation', data.presentation);
        formdata.append('curriculum', curriculum?.files?.item(0), data.curriculum);
        formdata.append('knowledge_source_id', data.knowledge_source_id);
        Object.entries(data.consents).forEach(([key, value], index) => {
          formdata.append(`consents[${index}][code]`, key);
          formdata.append(`consents[${index}][answer]`, value);
        });

        await contactService.sendWorkWithUs(formdata, reCaptchaToken);

        openFeedbackModal();

        resetForm({});
        jobRuleRef.current.select.clearValue();
      } catch (error) {
        toast.error(error?.response?.data?.message || 'Aconteceu um erro inesperado. Tente novamente mais tarde');
      } finally {
        hideLoading();
      }
    },
  });

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <AiFillCaretDown />
      </components.DropdownIndicator>
    );
  };

  const customStyles = {
    control: provided => ({
      ...provided,
      backgroundColor: '#f8f8f8',
      borderWidth: 0,
      paddingLeft: 20,
      minHeight: 56,
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
    indicatorsContainer: provided => ({
      ...provided,
      paddingRight: 10,
    }),
  };

  const loadJobRules = async () => {
    try {
      const data = [];
      data.push({ value: `Administrativa`, label: `Administrativa` });
      data.push({ value: `Biologia`, label: `Prof. Biologia` });
      data.push({ value: `Ciências`, label: `Prof. Ciências` });
      data.push({ value: `Coordenação`, label: `Coordenação` });
      data.push({ value: `Direção`, label: `Direção` });
      data.push({ value: `Filosofia`, label: `Prof. Filosofia` });
      data.push({ value: `Física`, label: `Prof. Física` });
      data.push({ value: `Geografia`, label: `Prof. Geografia` });
      data.push({ value: `História`, label: `Prof. História` });
      data.push({ value: `Inglês`, label: `Prof. Inglês` });
      data.push({ value: `Inspetoria`, label: `Inspetoria` });
      data.push({ value: `Marketing`, label: `Marketing` });
      data.push({ value: `Matemática`, label: `Prof. Matemática` });
      data.push({ value: `Pedagogia`, label: `Prof. Pedagogia` });
      data.push({ value: `Português`, label: `Prof. Português` });
      data.push({ value: `Química`, label: `Prof. Química` });
      data.push({ value: `Recepção`, label: `Recepção` });
      data.push({ value: `Redação`, label: `Prof. Redação` });
      data.push({ value: `Secretaria`, label: `Secretaria` });
      data.push({ value: `Sociologia`, label: `Prof. Sociologia` });
      data.push({ value: `Tecnologia`, label: `Tecnologia` });
      data.push({ value: `Outros`, label: `Outros` });
      setJobRules(data);
    } catch (e) {
      console.error(e);
    }
  };

  const handleSelectJobRules = jobs => {
    const ids = (jobs || []).map(({ value }) => value);
    setFieldValue('job_rule', ids);
  };

  const handleLoadConsent = (purposes = []) => {
    const newSchema = Yup.object().shape({
      ...schemaValues,
      consents: Yup.object().shape(
        purposes.reduce(
          (acc, { code, required }) => ({
            ...acc,
            [code]: required
              ? Yup.bool().oneOf([true], consentValidationMessage).required(consentValidationMessage)
              : Yup.bool(),
          }),
          {}
        )
      ),
    });

    setValidationSchema(newSchema);
  };

  useEffect(() => {
    loadJobRules();
  }, []);

  return (
    <>
      <FeedbackModal title="Mensagem enviada">
        <p>Sua mensagem foi enviada com sucesso. Em breve entraremos em contato</p>
      </FeedbackModal>
      <Form onSubmit={handleSubmit}>
        <SectionTitle align="left" title={` Trabalhe conosco`} mb="40px" />

        <Row>
          <Col lg={12}>
            <FormGroup mb="20px">
              <Label name="name" required>
                Nome
              </Label>
              <Input {...getFieldProps('name')} type="text" name="name" id="name" placeholder="Informe o nome" />
              <FieldError name="name" errors={errors} touched={touched} submitCount={submitCount} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <FormGroup mb="20px">
              <Label name="name" required>
                E-mail
              </Label>
              <Input {...getFieldProps('email')} type="text" name="email" id="email" placeholder="Informe o e-mail" />
              <FieldError name="email" errors={errors} touched={touched} submitCount={submitCount} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <FormGroup mb="20px">
              <Label name="cell_phone" required>
                Telefone
              </Label>
              <InputMask {...getFieldProps('phone')} mask="(99) 99999-9999" maskChar={null}>
                {inputProps => (
                  <Input {...inputProps} type="text" name="phone" id="phone" placeholder="Informe o telefone" />
                )}
              </InputMask>
              <FieldError name="phone" errors={errors} touched={touched} submitCount={submitCount} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <FormGroup mb="20px">
              <Label name="zip_code" required>
                CEP
              </Label>
              <InputMask {...getFieldProps('zip_code')} mask="99999-999" maskChar={null}>
                {inputProps => (
                  <Input {...inputProps} type="text" name="zip_code" id="zip_code" placeholder="Informe o CEP" />
                )}
              </InputMask>
              <FieldError name="zip_code" errors={errors} touched={touched} submitCount={submitCount} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <FormGroup mb="20px">
              <Label name="profession" required>
                Profissão
              </Label>
              <Input
                {...getFieldProps('profession')}
                type="text"
                name="profession"
                id="profession"
                placeholder="Informe sua profissão"
              />
              <FieldError name="name" errors={errors} touched={touched} submitCount={submitCount} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <FormGroup mb="20px">
              <Label name="job_rule" className="multi-select" required>
                Área de interesse
              </Label>
              <MultipleSelect
                placeholder="Selecione suas áreas de interesse"
                components={{ DropdownIndicator }}
                options={jobRules}
                styles={customStyles}
                onChange={handleSelectJobRules}
                closeMenuOnSelect={false}
                ref={jobRuleRef}
                isMulti
              />
              <FieldError name="job_rule" errors={errors} touched={touched} submitCount={submitCount} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <FormGroup mb="20px">
              <Label name="knowledge_source_id" required>
                Como nos conheceu?
              </Label>
              <Select {...getFieldProps('knowledge_source_id')} name="knowledge_source_id" id="knowledge_source_id">
                <option value="">Selecione uma opção</option>
                {knowledgeSources?.map(({ id, name }) => (
                  <option value={id}>{name}</option>
                ))}
              </Select>
              <FieldError name="knowledge_source_id" errors={errors} touched={touched} submitCount={submitCount} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <FormGroup mb="20px">
              <Label name="presentation" required>
                Apresentação
              </Label>
              <Textarea
                {...getFieldProps('presentation')}
                name="presentation"
                id="presentation"
                placeholder="Por favor, fale um pouco sobre você"
              />
              <FieldError name="presentation" errors={errors} touched={touched} submitCount={submitCount} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <FormGroup mb="20px">
              <Label name="curriculum" required>
                Curriculum
              </Label>
              <Input
                {...getFieldProps('curriculum')}
                type="file"
                name="curriculum"
                id="curriculum"
                accept="application/pdf"
              />
              <FieldError name="curriculum" errors={errors} touched={touched} submitCount={submitCount} />
            </FormGroup>
          </Col>
        </Row>
        <ConsentArea
          type={consentTypes.CONTACT_US}
          onLoad={handleLoadConsent}
          getFieldProps={getFieldProps}
          errors={errors}
          touched={touched}
          submitCount={submitCount}
        />
        <Row>
          <Col lg={12}>
            <SubmitButton isLoading={isLoading} />
          </Col>
        </Row>
      </Form>
    </>
  );
};

WorkWithUsForm.propTypes = {};

WorkWithUsForm.defaultProps = {};

export default WorkWithUsForm;
