/* eslint-disable react/jsx-filename-extension */
import PropTypes from 'prop-types';
import React from 'react';
import PageHeader from '../../components/pageheader';
import SEO from '../../components/seo';
import ContactFormArea from '../contact-form-area';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import WorkWithUsForm from '../work-with-us-form';

const WorkWithUsPage = ({ location, pageContext }) => (
  <>
    <SEO
      title="Trabalhe conosco - Curso Preparatório Garra - Bacacheri e Portão, Curitiba, PR"
      description="Trabalhe conosco - Melhor Cursinho Preparatório de Curitiba, Pré-vestibular, Pré-Militar"
      location={location}
    />
    <Header />
    <PageHeader pageContext={pageContext} location={location} title="Trabalhe conosco" />
    <ContactFormArea form={<WorkWithUsForm />} />
    <Footer />
  </>
);

WorkWithUsPage.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default WorkWithUsPage;
